import React, { useCallback, useState } from 'react';

import {
  MdWeekDate,
  ProductWithDetail,
  ProductWithDetailAccuracyEnum,
  ProductWithDetailShortageToleranceRankEnum,
  ShipmentReason,
} from '../../../api-client';
import { ModelType } from '../../../api-client/models/ModelType';

import {
  Dropdown,
  DropdownOption,
  Label,
  SentenceBox,
  SquareLabel,
  SquareLabelColor,
} from '../../atoms';

import {
  InventoryPlanTable,
} from '../../molecules';

import { DemandAggregation } from './DemandAggregation/DemandAggregation';
import './ProductPanel.css';

import {
  AccuracyLabelContainer,
  LabelContainer,
  ProductCodeLabel,
  ProductInfoContainer,
  ProductInfoLabel,
  ProductInfoValue,
  ProductNameLabel,
  ProductPanelContainer,
  ProductSpecLabel,
  ProductTitleContainer,
  ProductTitleLabelContainer,
} from './style';

const shortageToleranceRankDropdownOptions: Array<DropdownOption<ProductWithDetailShortageToleranceRankEnum>> = [
  {
    label: 'S',
    value: ProductWithDetailShortageToleranceRankEnum.S,
  },
  {
    label: 'A',
    value: ProductWithDetailShortageToleranceRankEnum.A,
  },
  {
    label: 'B',
    value: ProductWithDetailShortageToleranceRankEnum.B,
  },
  {
    label: 'C',
    value: ProductWithDetailShortageToleranceRankEnum.C,
  },
];

const accuracyLabelColor = (accuracy: ProductWithDetailAccuracyEnum): SquareLabelColor => {
  switch (accuracy) {
    case ProductWithDetailAccuracyEnum.Low:
      return 'red';
    case ProductWithDetailAccuracyEnum.Mid:
      return 'yellow';
    case ProductWithDetailAccuracyEnum.High:
      return 'green';
  }
};

const accuracyLabelText = (accuracy: ProductWithDetailAccuracyEnum): string => {
  switch (accuracy) {
    case ProductWithDetailAccuracyEnum.Low:
      return '調整';
    case ProductWithDetailAccuracyEnum.Mid:
      return '確認';
    case ProductWithDetailAccuracyEnum.High:
      return '信頼';
  }
};

const ModelLabelText = (modelType: ModelType): string => {
  switch (modelType) {
    case ModelType.LongTermModel:
      return '長期モデル';
    case ModelType.MiddleTermModel:
      return '中期モデル';
    case ModelType.ShortTermModel:
      return '短期モデル';
    case ModelType.Undefined:
      return "";
    default:
      const _ :never = modelType;
      return _;
  }
};

export interface ProductPanelProps {
  /**
   * SKU
   */
  product: ProductWithDetail

  /**
   * 過去のMD週
   */
  pastMdWeekDates: Array<MdWeekDate>

  /**
   * 今日・未来のMD週
   */
  presentAndFutureMdWeekDates: Array<MdWeekDate>

  /**
   * 読み取り専用かどうか
   */
  readonly: boolean

  /**
   * 調整アラート停止が実行可能か
   */
  isStoppableAdjustmentAlert: boolean

  /**
   * 発注グループID
   */
  orderGroupId: number

  /**
   * 欠品許容ランクが変更された時に呼び出されるハンドラー
   */
  onShortageToleranceRankChangeHandler: (shortageToleranceRank: ProductWithDetailShortageToleranceRankEnum) => void

  /**
   * 出荷加減要素が変更された時に呼び出されるハンドラー
   */
  onShipmentReasonChangeHandler: (mdWeekDate: MdWeekDate, shipmentReason: ShipmentReason | null) => void

  /**
   * 出荷加減数が変更された時に呼び出されるハンドラー
   */
  onShipmentQuantityChangeHandler: (index: number, mdWeekDate: MdWeekDate, quantity: number | null, hasError: boolean) => void

  /**
   * 発注確定数が変更された時に呼び出されるハンドラー
   */
  onConfirmedOrderQuantityChangeHandler: (index: number, mdWeekDate: MdWeekDate, quantity: number | null, hasError: boolean) => void

  /**
   * 店頭在庫基準数が変更された時に呼び出されるハンドラー
   */
  onStoreInventoryQuantityStandardChangeHandler: (index: number, mdWeekDate: MdWeekDate, quantity: number | null, hasError: boolean) => void

  /**
   * 入力フォームからフォーカスが外れた時に呼び出されるハンドラー
   */
  onBlurInputHandler: () => void

  /**
   * 修正発注数の横の×ボタンが押された時に呼び出されるハンドラー
   */
  onClickStopAdjustmentAlertHandler: (date: string, prodcutCode: string) => void

  /**
   * 在庫調整画面に必要なデータを再取得する
   */
  getDestocking: () => Promise<void>

  /**
   * SKUのmeta情報を取得する。
   */
  updateSkuMeta: (productCode: string, comment: string) => Promise<void>

  /**
   * 販売予測数(ユーザー)が変更された時に呼び出されるハンドラー
   */
  onExpectedSalesAmountByUserChangeHandler: (index:number, mdWeekDate: MdWeekDate, quantity: number | null, hasError: boolean) => void

  /**
   * パネルのインデックス
   */
  index: number;
}

export const ProductPanel: React.FC<ProductPanelProps> = React.memo(({
  product,
  pastMdWeekDates,
  presentAndFutureMdWeekDates,
  readonly,
  orderGroupId,
  isStoppableAdjustmentAlert,
  onShortageToleranceRankChangeHandler,
  onShipmentReasonChangeHandler,
  onShipmentQuantityChangeHandler,
  onConfirmedOrderQuantityChangeHandler,
  onStoreInventoryQuantityStandardChangeHandler,
  onBlurInputHandler,
  onClickStopAdjustmentAlertHandler,
  getDestocking,
  updateSkuMeta,
  onExpectedSalesAmountByUserChangeHandler,
  index,
}) => {
  // ハイライト対象のカラムのインデックス
  const [highlightedColIndex, setHighlightedColIndex] = useState(-1);
  const handleHighlightedColIndexChange = useCallback((colIdx: number) => {
    setHighlightedColIndex((prevIndex) => (prevIndex === colIdx ? -1 : colIdx));
  }, []);

  const handleExpectedSalesAmountByUserChange = useCallback(
    (mdWeekDate: MdWeekDate, quantity: number | null, hasError: boolean) => {
      onExpectedSalesAmountByUserChangeHandler(index, mdWeekDate, quantity, hasError);
    },
    [index, onExpectedSalesAmountByUserChangeHandler]
  );

  const handleShipmentQuantityChangeHandler = useCallback(
    (mdWeekDate: MdWeekDate, quantity: number | null, hasError: boolean) => {
      onShipmentQuantityChangeHandler(index, mdWeekDate, quantity, hasError);
    },
    [index, onShipmentQuantityChangeHandler]
  );

  const handleConfirmedOrderQuantityChangeHandler = useCallback(
    (mdWeekDate: MdWeekDate, quantity: number | null, hasError: boolean) => {
      onConfirmedOrderQuantityChangeHandler(index, mdWeekDate, quantity, hasError);
    },
    [index, onConfirmedOrderQuantityChangeHandler]
  );

  const handleStoreInventoryQuantityStandardChangeHandler = useCallback(
    (mdWeekDate: MdWeekDate, quantity: number | null, hasError: boolean) => {
      onStoreInventoryQuantityStandardChangeHandler(index, mdWeekDate, quantity, hasError);
    },
    [index, onStoreInventoryQuantityStandardChangeHandler]
  );

  return (
    <ProductPanelContainer>
      <ProductTitleLabelContainer>
        {
          (() => {
            if (product.hasDemandInsightData === false) {
              return (
                <LabelContainer>
                  <Label color='grey' text='新規SKU' width={75} />
                </LabelContainer>
              );
            }

            if (product.hasDemandInsightData) {
              return (
                <>
                  {product.accuracy != null ?
                    (
                      <AccuracyLabelContainer>
                        <SquareLabel
                          color={accuracyLabelColor(product.accuracy)}
                          text={accuracyLabelText(product.accuracy)}
                          width={44}
                        />
                      </AccuracyLabelContainer>
                    ) : null
                  }
                  { product.modelType !== "undefined" ?
                    (
                      <AccuracyLabelContainer>
                        <SquareLabel
                          color={'grey'}
                          text={ModelLabelText(product.modelType)}
                          width={103}
                        />
                      </AccuracyLabelContainer>
                    ) : null
                  }
                  {product.isNeedAdjustment?
                    (
                      <LabelContainer>
                        <Label color='yellow' text='調整アラート' width={102} />
                      </LabelContainer>
                    ) : null
                  }
                  {(product.hasSubcategoryPrediction === false)?
                    (
                      <LabelContainer>
                        <SquareLabel
                          color={'red'}
                          text={'サブカテゴリ予測使用不可'}
                          width={180}
                        />
                      </LabelContainer>
                    ) : null
                  }
                </>
              );
            }
          }) ()}
      </ProductTitleLabelContainer>

      <ProductTitleContainer>

        <ProductCodeLabel>{product.code}</ProductCodeLabel>
        {product.name == null && product.specName == null? (
          <ProductNameLabel>-</ProductNameLabel>
        ) : (
          <React.Fragment>
            <ProductNameLabel>{product.name != null? product.name : '-'}</ProductNameLabel>
            <ProductSpecLabel>{product.specName != null? `(${product.specName})` : '-'}</ProductSpecLabel>
          </React.Fragment>
        )}
      </ProductTitleContainer>

      <ProductInfoContainer>
        <ProductInfoLabel>売価：</ProductInfoLabel>
        <ProductInfoValue>{product.price != null? product.price : '-'}</ProductInfoValue>
        <ProductInfoLabel>店発単：</ProductInfoLabel>
        <ProductInfoValue>{product.minNumberOfProductsPerDelivery != null? product.minNumberOfProductsPerDelivery : '-'}</ProductInfoValue>
        <ProductInfoLabel>入数：</ProductInfoLabel>
        <ProductInfoValue>{product.quantityPerCase}</ProductInfoValue>
        <ProductInfoLabel>M3：</ProductInfoLabel>
        <ProductInfoValue data-testid='product-panel-m3'>{Math.ceil(product.m3 * 100) / 100}</ProductInfoValue>
        <ProductInfoLabel>店舗数：</ProductInfoLabel>
        <ProductInfoValue data-testid='product-panel-number-of-stores'>{product.numberOfStores.toLocaleString()}</ProductInfoValue>
        <ProductInfoLabel>欠品許容ランク：</ProductInfoLabel>
        <div className='product-info-space'>
          <Dropdown
            options={shortageToleranceRankDropdownOptions}
            defaultValue={product.shortageToleranceRank}
            width={57}
            height={30}
            disabled={readonly}
            highlightOnChange={true}
            onChangeHandler={(value) => {
              onShortageToleranceRankChangeHandler(value);
            }}
          />
        </div>
        <div className='product-info-space'>
          <DemandAggregation
            startDate={product.demandAggregation.startDate ?? null}
            endDate={product.demandAggregation.endDate ?? null}
            orderGroupId={orderGroupId}
            product={product}
            disabled={readonly}
            cbAfterPut={getDestocking}
          />
        </div>
        <div className='product-info-space'>
          <SentenceBox
            dataTestId={product.code + '-sentence-box'}
            defaultValue={product.comment ?? ''}
            disabled={readonly}
            max={30}
            onBlurHandler={async (comment, errorMessage) => {
              if (errorMessage !== '') return;
              await updateSkuMeta(product.code, comment);
            }}
          />
        </div>
      </ProductInfoContainer>
      <InventoryPlanTable
        readonly={readonly}
        productCode={product.code}
        hasDemandInsightData={product.hasDemandInsightData}
        inventoryPlanPerMdWeek={product.inventoryPlanPerMdWeek}
        pastMdWeekDates={pastMdWeekDates}
        presentAndFutureMdWeekDates={presentAndFutureMdWeekDates}
        highlightedColIndex={highlightedColIndex}
        onHighlightedColIndexChangeHandler={handleHighlightedColIndexChange}
        isStoppableAdjustmentAlert={isStoppableAdjustmentAlert}
        onShipmentReasonChangeHandler={onShipmentReasonChangeHandler}
        onShipmentQuantityChangeHandler={handleShipmentQuantityChangeHandler}
        onConfirmedOrderQuantityChangeHandler={handleConfirmedOrderQuantityChangeHandler}
        onStoreInventoryQuantityStandardChangeHandler={handleStoreInventoryQuantityStandardChangeHandler}
        onBlurInputHandler={onBlurInputHandler}
        onClickStopAdjustmentAlertHandler={(date: string, prodcutCode: string) => {
          onClickStopAdjustmentAlertHandler(date, prodcutCode);
        }}
        onExpectedSalesAmountByUserChangeHandler={handleExpectedSalesAmountByUserChange}
      />
    </ProductPanelContainer>
  );
}, (prevProps, nextProps) => {
  return prevProps.product === nextProps.product &&
    prevProps.pastMdWeekDates === nextProps.pastMdWeekDates &&
    prevProps.presentAndFutureMdWeekDates === nextProps.presentAndFutureMdWeekDates &&
    prevProps.readonly === nextProps.readonly &&
    prevProps.isStoppableAdjustmentAlert === nextProps.isStoppableAdjustmentAlert;
});
